import './App.css';
import React, {useEffect} from 'react';

import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  useLocation
} from 'react-router-dom';
import Landing from './components/pages/Home';
import Services from './components/pages/services';
import Insights from './components/pages/insights';
import Contact from './components/pages/contact';
import Topbar from './components/navbar';
import BottomBar from './components/BottomBar';
import NotFound from './components/pages/NotFound';
import BaselineDevelopment from './components/pages/services/BaselineDevelopment';
import ImpactAnalysis from './components/pages/services/ImpactAnalysis';
import EcosystemRestoration from './components/pages/services/EcosystemRestoration';
import EconomicValuation from './components/pages/services/EconomicValuation';
import TrainingEducation from './components/pages/services/TrainingEducation';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
      });  
  }, [pathname]); // Only re-run the effect if the pathname changes

  return null;
  }

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Topbar/>
        <Routes>
          <Route exact path='/' element={<Landing/>} />
          <Route path='/Services'>
            <Route index={true} element={<Services/>} />
            <Route path='Baseline-Development' element={<BaselineDevelopment/>} />
            <Route path='Impact-Analysis' element={<ImpactAnalysis/>} />
            <Route path='Ecosystem-Restoration' element={<EcosystemRestoration/>} />
            <Route path='Economic-Valuation' element={<EconomicValuation/>} />
            <Route path='Training-and-Education' element={<TrainingEducation/>} />
          </Route>
          <Route path='Insights' element={<Insights/>} />
          <Route path='Contact' element={<Contact/>} />
          <Route path='*' element={<NotFound/>} />
        </Routes>
        <BottomBar/>
      </Router>
      
    </div>
  );
}

export default App;
