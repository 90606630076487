import React, { useState } from "react";
import './navbar.css'
import { NavLink } from "react-router-dom";

export default function Topbar() {
    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if(window.scrollY >= 50) {
        setNavbar(true);
        } else {
        setNavbar(false);
        }
    }
    window.addEventListener('scroll', changeBackground);

    return (
        <nav className={navbar ? 'navbar scrolled': 'navbar'}> 
            <div className="logo">
            </div>
            <ul className="button-menu">
                <li>
                    <NavLink to="/">Home</NavLink>
                </li>
                <li>
                    <NavLink to="/Services">Services</NavLink>
                </li>
                <li>
                    <NavLink to="/Insights">Articles</NavLink>
                </li>
                <li>
                    <NavLink to="/Contact">Contact</NavLink>
                </li>
                <li>
                    <a href="https://www.instagram.com/oceanara.id/?hl=en" target="blank" className="last-child">Oceanara</a>
                </li>
            </ul>
        </nav>
    );
}