import React, {useState} from "react";
import './services.css';
import contentOne from '../../media/ocean-bg.webp';

export default function BaselineDevelopment() {
    const [collapseOne, setCollapseOne] = useState(false);
    const [collapseTwo, setCollapseTwo] = useState(false);
    const handleClickOne = () => setCollapseOne(!collapseOne);
    const handleClickTwo = () => setCollapseTwo(!collapseTwo);

    return (
        <div className="halaman-subservices"> 
            <h1>Baseline Development</h1>
            <div className="container">
                <button onClick={handleClickOne} className="button-collapse">
                    <h2>Scope of Work</h2>
                </button>
                <div 
                    className={ collapseOne? "content": "content close"}
                >
                    <img src={contentOne} alt="img content" className="img-content"/>
                    <div className="paragraph-container" >
                        <p>
                            Data collection on biodiversity, population, density, socio-economic,
                            and the potential of coastal ecosystems.
                            Pendataan keanekaragaman, populasi, kerapatan, sosial - ekonomi, 
                            dan potensi ekosistem pesisir.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
                <button onClick={handleClickTwo} className="button-collapse">
                    <h2>Cases</h2>
                </button>
                <div className={collapseTwo ? "content" : "content close"}>
                    <img src={contentOne} alt="img content" width="240px" height="180px"/>
                    <p>Pembuatan Data Pendukung AMDAL, KLHS, RKKPR, dan CSR.</p>
                </div>
            </div>
        </div>
    );
}