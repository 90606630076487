import React from "react";
import './BottomBar.css';
import imgBottom from './media/Cropped BNB Symbol Logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function BottomBar() {
    return (
        <div className="main-body">
            <div className="tempat-logo">
                <img src={imgBottom} alt="logo bumi nautika" width='80px'/>
                <p>Coastal Ecosystem Restoration <br/>Research and Consulting</p>
            </div>
            <div className="tempat-contact">
                <h2>Contact</h2>
                <p>
                    <FontAwesomeIcon icon={faLocationDot} /> <span />
                    Gd. Wirausaha Lt. 1 Unit 104, Jl. Rasuna Said Kav. C-5, Kel. Karet, Kec. Setiabudi, Jakarta Selatan 12920
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone} /> <span />
                    +62 857-2159-2234
                </p>
                <p>
                    <FontAwesomeIcon icon={faEnvelope} /> <span />
                    <a href="mailto: office@buminautika.com">office@buminautika.com</a>

                </p>
            </div>
        </div> 
    )
};