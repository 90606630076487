import React, { useState } from "react";
import './services.css';
import contentOne from '../../media/ocean-bg.webp';

export default function ImpactAnalysis() {
    const [collapseOne, setCollapseOne] = useState(false);
    const [collapseTwo, setCollapseTwo] = useState(false);
    const handleClickOne = () => setCollapseOne(!collapseOne);
    const handleClickTwo = () => setCollapseTwo(!collapseTwo);

    return (
        <div className="halaman-subservices"> 
            <h1>Hello Bumi Nautika App</h1>
            <h2> Impact Analysis Page</h2>
            <div className="container">
                <button onClick={handleClickOne} className="button-collapse">
                    <h2>Scope of Work</h2>
                </button>
                <div className={collapseOne ? "content" : "content close"}>
                    <img src={contentOne} alt="img content" width="240px" height="180px"/>
                    <p>
                        Risk assessment and analysis of economic losses of coastal ecosystems. 
                        Penilaian resiko dan analisis kerugian ekonomi ekosistem pesisir.
                    </p>
                </div>
            </div>
            <div className="container">
                <button onClick={handleClickTwo} className="button-collapse">
                    <h2>Cases</h2>
                </button>
                <div className={collapseTwo ? "content" : "content close"}>
                    <img src={contentOne} alt="img content" width="240px" height="180px"/>
                    <p>Kerusakan Pesisir Akibat Kapal Kandas dan Pembangunan Infrastruktur.</p>
                </div>
            </div>
        </div>
    );
}